import React from "react";

const AccountHistory = () => {
  return (
    <div className="w-full 2xl:w-1/2 bg-grey-light rounded-2xl p-4 h-[392px]">
      <h4 className="text-heading-4-bold">Account History</h4>

      <h3 className="text-heading-3-bold italic text-grey">Coming Soon...</h3>
      {/* <div className="flex">
        <div className="w-3/4">
          You have requested for withdrawal of INR XXXXXXX .It will take 5-7
          Business days to process.
        </div>
        <div className="w-1/4">Lorem, ipsum.</div>
      </div> */}
    </div>
  );
};

export default AccountHistory;
